
  import { Component, Prop, Vue } from "vue-property-decorator";
  import LzButton from "@/components/Button.vue";
  import LzTable from "@/components/Table.vue";
  import LzConfirm from "@/components/Confirm.vue";
  import { namespace } from "vuex-class";
  import { apiProjects } from "../api";
  import LzEditorInput from "@/components/EditorInput.vue";
  import { getImgURL } from "@/utils/getFormulateImageUrl";

  const auth = namespace("auth");

  type TProjectForm = {
    title: string;
    description: string;
    date: string;
    skills: string;
    status: "enabled" | "disabled";
    imageUrlToConvert: { url: string }[];
    imagesToConvert: { url: string }[] | any;
    memberId: string;
    imageUrl: string;
    images: string[];
  };

  @Component({ components: { LzButton, LzTable, LzConfirm, LzEditorInput } })
  export default class ProjectsCreate extends Vue {
    loaded = false;
    isAlreadyPremiumSection = false;
    isPremiumSubscription = false;

    proyectForm: TProjectForm = {
      memberId: "",
      title: "",
      description: "",
      status: "disabled",
      skills: "",
      imageUrlToConvert: [],
      imageUrl: "",
      date: "",
      imagesToConvert: [],
      images: []
    };

    @auth.State("id")
    public memberId!: string;

    @Prop()
    projectId!: string;

    statusOptions = {
      enabled: this.$t("projects.create.form.status.options.enabled"),
      disabled: this.$t("projects.create.form.status.options.disabled")
    };

    async getProject() {
      const project = await apiProjects.getProject(this.projectId);

      this.proyectForm.title = project.title;
      this.proyectForm.description = project.description;
      this.proyectForm.date = project.date.split("T")[0];
      this.proyectForm.skills = project.skills;
      this.proyectForm.status = project.status;
      this.proyectForm.imageUrlToConvert = [{ url: project.image_url }];
      this.proyectForm.imagesToConvert = project.images.map(url => ({ url }));
    }

    async createProject(payload: TProjectForm) {
      try {
        await apiProjects.postProject({
          date: payload.date,
          description: payload.description,
          image_url: payload.imageUrl,
          member_id: this.memberId,
          skills: payload.skills,
          status: payload.status,
          images: payload.images,
          title: payload.title
        });

        this.$notify({
          type: "success",
          text: this.$tc("projects.create.notifications.projectCreated")
        });
        this.$router.push({ name: "projectsRead" });
      } catch (error) {
        this.$notify({
          type: "error",
          text: this.$tc("common.error.generic")
        });
      }
    }

    async updateProject(payload: TProjectForm) {
      try {
        await apiProjects.updateProject(this.projectId, {
          date: payload.date,
          description: payload.description,
          image_url: payload.imageUrl,
          member_id: this.memberId,
          skills: payload.skills,
          status: payload.status,
          images: payload.images,
          title: payload.title
        });

        this.$notify({
          type: "success",
          text: this.$tc("projects.create.notifications.projectEdited")
        });
        this.$router.push({ name: "projectsRead" });
      } catch (error) {
        this.$notify({
          type: "error",
          text: this.$tc("common.error.generic")
        });
      }
    }

    async onSubmit() {
      const isNewProject = !this.projectId;

      const parsedImages: string[] = [];

      const imageUrl = (await getImgURL(
        this.proyectForm.imageUrlToConvert
      )) as string;

      this.proyectForm.imagesToConvert.files.forEach(async element => {
        if (element) {
          parsedImages.push(element?.path?.url);
        }
      });

      const body: TProjectForm = {
        ...this.proyectForm,
        imageUrl,
        images: parsedImages,
        memberId: this.memberId
      };

      if (isNewProject) {
        await this.createProject(body);
      } else {
        await this.updateProject(body);
      }
    }

    async mounted() {
      try {
        if (!this.projectId) {
          this.loaded = true;
          return;
        }
        await this.getProject();
      } catch (error) {
        this.$notify({
          type: "error",
          text: this.$tc("common.error.generic")
        });
      }

      this.loaded = true;

      this.proyectForm.memberId = this.memberId;
    }
  }
